import React, { useState, useReducer } from "react"
import Layout from "../components/Layout"
import styled from "styled-components"

import { motion } from "framer-motion"
import { graphql } from "gatsby"
import Meta from "../components/Meta"
import Nav from "../components/Nav"
import Footer from "../components/Footer"
import MobileMenu from "../components/MobileMenu"
import PageTitle from "../components/ui/PageTitle"
import Banner from "../components/Banner"

import RenderBlock from "../components/RenderBlock"

import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai"

const StyledContent = styled.div`
  margin-bottom: 60px;
  .category-title {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    margin: 50px 0 5px 0;
    text-transform: uppercase;
    color: var(--red);
    letter-spacing: 1.3px;
    padding: 0 35px;
    &::selection {
      color: var(--white);
    }
  }
  .category-description {
    text-align: center;
    margin-bottom: 20px;
    padding: 0 30px;
  }
  .category-group {
    hr {
      opacity: 0.3;
      margin: 0;
    }
    .btn {
      width: 100%;
      display: flex;
      justify-content: space-between;
      background: transparent;
      border: transparent;
      position: relative;
      padding: 15px;
      font-size: 16px;
      text-align: left;
      span {
        margin-left: 20px;
        svg {
          font-size: 17px;
        }
      }
      &:hover {
        color: var(--red);
      }
      &--expanded {
        color: var(--red);
      }
    }
    .description {
      margin-bottom: 10px;
      padding: 0 15px;
      p {
        margin-bottom: 3px;
      }
    }
  }
`
export default function FaqPage({ data }) {
  const categories = data.allSanityCategory.nodes,
    faqList = data.allSanityFaq.nodes,
    details = data.sanityDetails,
    faqData = data.sanityFaqDetails

  const defaultState = {}

  faqList.forEach(item => {
    let newKey = item["id"]
    defaultState[newKey] = false
  })

  const [expanded, setExpanded] = useState(defaultState)
  const [, forceUpdate] = useReducer(x => x + 1, 0)

  const handleClickExpandable = (id, list) => {
    for (const [key] of Object.entries(list)) {
      if (key === id) {
        list[key] = !list[key]
      } else {
        list[key] = false
      }
    }

    setExpanded(list)
    forceUpdate()
  }
  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        delayChildren: 0.5,
      },
    },
  }

  const displayedFaq = categories
    .sort((a, b) => a?.order - b?.order)
    .map(category => {
      let collapsable = faqList.filter(f => f.category.id === category.id)

      const displayedCollapsable = collapsable
        .sort((a, b) => a?.order - b?.order)
        .map(item => {
          return (
            <motion.li variants={item} key={item.id}>
              <button
                onClick={() => handleClickExpandable(item.id, expanded)}
                className={expanded[item.id] ? "btn btn--expanded" : "btn"}
              >
                {item.title}{" "}
                <span>
                  {expanded[item.id] ? <AiOutlineMinus /> : <AiOutlinePlus />}
                </span>
              </button>

              {expanded[item.id] ? (
                <motion.div
                  animate={{ y: -10 }}
                  transition={{ type: "spring", stiffness: 100 }}
                  className="description"
                >
                  <RenderBlock
                    block={item.description}
                    className={"description-block"}
                  />
                </motion.div>
              ) : (
                ""
              )}
              <hr />
            </motion.li>
          )
        })

      return (
        collapsable.length > 0 && (
          <div className="category-group" key={category.id}>
            <h3 className="category-title">{category.title}</h3>
            <p className="category-description">{category.description}</p>
            <hr />
            <motion.ul variants={container} initial="hidden" animate="show">
              {displayedCollapsable}
            </motion.ul>
          </div>
        )
      )
    })

  return (
    <div className="page">
      <Meta title="FAQ" />
      <Layout>
        <Nav
          phoneNumber={details.phone}
          phoneIcon={details.contactIcon}
          name={details.shortName}
          logo={details.logo}
        />
        <MobileMenu
          phoneNumber={details.phone}
          phoneIcon={details.contactIcon}
          copyrightMessage={details.copyrightMessage}
          agencyName={details.agencyName}
          agencyLink={details.agencyLink}
        />
        <main className="content">
          <PageTitle title={faqData.pageTitle} />
          <Banner data={faqData.bannerText} image={faqData.bannerBackground} />
          <StyledContent>{displayedFaq}</StyledContent>
          {faqData.ctaEnabled && (
            <div style={{ margin: "40px 20px" }}>
              <strong>{faqData.ctaMessage}</strong>
              <a href={`mailto:${details.email}`}>
                <span className="text">{details.email}</span>
              </a>
            </div>
          )}
        </main>
        <Footer
          copyrightMessage={details.copyrightMessage}
          agencyName={details.agencyName}
          agencyLink={details.agencyLink}
        />
      </Layout>
    </div>
  )
}

export const enquiriesPage = graphql`
  query FaqQuery {
    sanityDetails {
      contactIcon {
        ...ImageWithPreview
      }
      logo {
        ...ImageWithPreview
      }
      phone
      longName
      copyrightMessage
      agencyName
      agencyLink
      email
    }
    allSanityFaq {
      nodes {
        title
        id
        order
        description {
          children {
            _key
            _type
            marks
            text
          }
          _type
          _key
          list
          style
        }
        category {
          id
          title
        }
      }
    }
    allSanityCategory {
      nodes {
        title
        description
        order
        id
      }
    }
    sanityFaqDetails {
      pageTitle
      ctaEnabled
      ctaMessage
      bannerText {
        children {
          _key
          _type
          marks
          text
        }
        _type
        _key
        list
        style
      }
      bannerBackground {
        asset {
          url
          id
          publicUrl
        }
        hotspot {
          _key
          _type
          x
          y
          height
          width
        }
      }
    }
  }
`
